<template>
  <div class="p-4">
    <div class="register-box">

      <div class="logo">
        <img src="../assets/logo-desc.png" alt="">
      </div>

      <div class="form-box text-center">

        <div class="circle-progress">
          <div class="percent">
            <h3><span>%</span>{{ percentage }}</h3>
          </div>
          <circle-progress
              empty-color="#f4f5f7"
              size="210"
              fill-color="#fff"
              background="#fff"
              :border-width="9"
              :border-bg-width="20"
              :percent="percentage"
              :is-gradient="true"
              :gradient="{
        angle: 90,
        startColor: '#CFBFFF',
        stopColor: '#3300CC'
    }"
          />
        </div>


        <!--        <div class="card-progress">
                  <div class="percent">
                    <svg>
                      <circle cx="105" cy="105" r="100"></circle>
                      <circle cx="105" cy="105" r="100" :style="'&#45;&#45;percent:'+percentage"></circle>
                      <pattern id="pattern" patternUnits="userSpaceOnUse" width="210" height="210">
                        <image xlink:href="../assets/img/gradient.png" x="0" y="0" width="210" height="210"></image>
                      </pattern>
                    </svg>
                    <div class="number">
                      <h3>{{ percentage }}<span>%</span></h3>
                    </div>
                  </div>

                </div>-->


        <div class="bottom">
          <h4>داشبورد شما در حال آماده سازیست...</h4>
          <span>از صبوری شما سپاسگذاریم.</span>
        </div>

      </div>

      <img class="bg" src="../assets/img/half-circle.png" alt="">

    </div>
  </div>
</template>

<script>
import axios from "axios";
import store from "@/store";
import Cookies from "js-cookie";
import "vue3-circle-progress/dist/circle-progress.css";
import CircleProgress from "vue3-circle-progress";

export default {
  name: "LoadingView",
  components: {CircleProgress},
  data() {
    return {
      apiUrl: store.getters.baseUrl,
      loading: false,
      snackbar: false,
      textSnack: false,
      colorSnack: '',
      percentage: 1,
      count: 0,
      intervalId: null,
      intervalId2: null,
      headers: {
        'Accept': 'Application/json',
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
    }
  },
  methods: {
    getCookie() {
      return Cookies.get('token');
    },
    animateNumbers(start, end) {
      let counter = start;
      const step = end > start ? 1 : -1; // جهت شمارش
      const interval = setInterval(() => {
        if (counter !== end) {
          counter += step;
          this.percentage = counter;
        } else {
          clearInterval(interval);
        }
      }, 50);
    },
    getNode() {
      let token = this.getCookie()
      let auth = 'Bearer ' + token
      this.headers.Authorization = auth
      axios.get('http://46.102.130.145:8001/api/node-creation-status', {headers: this.headers})
          .then(res => {
            const nextNumber = Math.ceil(res.data.percentage)

            if (this.percentage < 100) {

              this.animateNumbers(this.percentage, nextNumber);
              this.percentage = nextNumber;
            } else {
              this.percentage = 100
              clearInterval(this.intervalId2);
              clearInterval(this.intervalId);
              Cookies.set('token', res.data.api_token, {expires: 7, path: 'https://agistant.com/'});
              localStorage.setItem('urlPanel',res.data.url	)
              this.$router.push({ name: 'Completed'})
            }

          }).catch(err => {
        if (err?.response?.status === 401) {
          this.$router.push({ name: 'Register'})

        }

      })
    }
  },
  beforeUnmount() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
  mounted() {
    this.getNode()
    this.intervalId = setInterval(this.getNode, 3000);
  }
}
</script>

<style scoped lang="scss">

.circle-progress {
  width: 210px;
  height: 210px;
  position: relative;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;

  .percent {
    position: absolute;
    z-index: 1;
    span{
      font-size: 30px;
    }

    h3 {
      font-size: 50px;
      margin-bottom: 0;
      font-family: Tahoma,serif;
    }
  }
}

.card-progress {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  width: 280px;
  position: relative;

  .percent {
    position: relative;
  }

  svg {
    position: relative;
    width: 210px;
    height: 210px;
    transform: rotate(-90deg);
    z-index: 1;

    circle {
      width: 100%;
      height: 100%;
      fill: none;
      stroke: #f3f4f6;
      stroke-width: 10;
      stroke-linecap: round;

    }

    circle:last-of-type {
      stroke-dasharray: 625px;
      stroke-dashoffset: calc(625px - (625px * var(--percent)) / 100);
      stroke: url(#pattern);
      stroke-width: 10;
    }

  }

  .number {
    position: absolute;
    width: 100%;
    border-radius: 50%;
    background: #fff;
    bottom: 0;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 22px solid #f3f4f6;

    h3 {
      font-weight: 500;
      font-size: 2.5rem;


    }

  }

  .title h2 {
    margin: 25px 0 0;
  }


}


.progress-cycle {
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;

  div {
    width: 286px;
    height: 286px;
    background: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    i {
      font-size: 64px;
      font-weight: 500;
      font-style: normal;
    }

    span {
      width: 100%;
      height: 100px;
      background-color: transparent;
      border-top-left-radius: 110px;
      border-top-right-radius: 110px;
      border: 10px solid gray;
      border-bottom: 0;
      position: absolute;
    }

  }
}

.form-box {
  min-width: 100%;
}

.loading-images {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 140px;
  position: relative;
  margin: 0 auto;
  height: 240px;

  img {
    width: auto;
    position: absolute;
    top: 0;
    z-index: 3;
    opacity: 0;

    &.loading1 {
      animation-name: anim1;
      animation-duration: 4s;
      animation-delay: 1s;
      animation-iteration-count: infinite;
    }

    &.loading2 {
      top: 64px;
      z-index: 2;
      animation-name: anim2;
      animation-duration: 4s;
      animation-delay: .5s;
      animation-iteration-count: infinite;
    }

    &.loading3 {
      top: 127px;
      z-index: 1;
      animation-name: anim3;
      animation-duration: 4s;
      animation-iteration-count: infinite;
    }

    @keyframes anim3 {
      0% {
        top: 0;
        opacity: 0
      }
      50% {
        top: 127px;
        opacity: 1
      }
      100% {
        top: 160px;
        opacity: 0
      }
    }
    @keyframes anim2 {
      0% {
        top: 0;
        opacity: 0
      }
      50% {
        top: 64px;
        opacity: 1
      }
      100% {
        top: 127px;
        opacity: 0
      }
    }
    @keyframes anim1 {
      0% {
        top: 0;
        opacity: 0
      }
      50% {
        top: 2px;
        opacity: 1
      }
      100% {
        top: 120px;
        opacity: 0
      }
    }

  }
}

.bottom {
  margin-top: 7rem;

  h4 {
    font-weight: bold;
    font-size: 32px;
    margin-bottom: 20px;
  }
}

</style>
