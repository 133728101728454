import {createRouter, createWebHistory} from 'vue-router';
import Login from "@/components/auth/Login";
import Home from "@/components/Home";
import Register from "@/components/auth/Register";
import Otp from "@/components/auth/Otp";
import Information from "@/components/auth/Information";
import Password from "@/components/auth/Password";
import Forget from "@/components/auth/Forget";
import Loading from "@/components/Loading";
import CompletedComponent from "@/components/Completed";
const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/loading',
        name: 'Loading',
        component: Loading,
    },
    {
        path: '/completed',
        name: 'Completed',
        component: CompletedComponent,
    },
    {
        path: '/auth',
        children: [
            {
                path: 'login',
                name: 'Login',
                component: Login,
            },
            {
                path: 'register',
                name: 'Register',
                component: Register,
            },
            {
                path: 'otp',
                name: 'Otp',
                component: Otp,
            },
            {
                path: 'organizational-information',
                name: 'Information',
                component: Information,
            },
            {
                path: 'choice-password',
                name: 'Password',
                component: Password,
            },
            {
                path: 'forget-pass',
                name: 'Forget',
                component: Forget,
            },
        ],
    },




];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
