import {createStore} from 'vuex';

const store = createStore({
    state: {
        baseUrlAPi: 'http://46.102.130.145:8001/',
        Authenticated: false,
        token: '',
        mobileNumber: '',
        nodeId: '',
        information: {
            company_personnel: '',
            company_name: '',
        }


    },
    mutations: {
        setInformation(state, data) {
            state.information.company_personnel = data.company_personnel
            state.information.company_name = data.company_name

        },
        setLogout(state) {
            state.Authenticated = false
            state.token = ''
        },
        setNodeId(state, id) {
            state.nodeId = id
        },
        setLogin(state, token) {
            state.Authenticated = true
            state.token = token
        },
        setMobile(state, mobile) {
            state.mobileNumber = mobile
        }
    },
    actions: {
        logout({commit}, newVal) {
            commit('setLogout', newVal);
        },
        saveLogin({commit}, newVal) {
            commit('setLogin', newVal);
        },
        saveNodeId({commit}, newVal) {
            commit('setNodeId', newVal);
        },
        updateProfile({commit}, newVal) {
            commit('setProfile', newVal);
        },
        setMobile({commit}, newVal) {
            commit('setProfile', newVal);
        },
    },
    getters: {
        isAuthenticated(state) {
            return state.Authenticated
        },
        getInformation(state) {
            return state.information
        },
        getToken(state) {
            return state.token
        },
        getNodeId(state){
            return state.nodeId
        },
        baseUrl(state) {
            return state.baseUrlAPi
        },
        getMobile(state) {
            return state.mobileNumber
        }
    },
});

export default store;
