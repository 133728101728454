<template>
  <div class="p-2 m-md-4">

    <div id="section1" class="main">
      <Header></Header>

      <div class="text-main">
        <span>به اجیستنت خوش آمدید! 👋</span>

        <h1>کامل ترین سیستم سازمانی یکپارچه آنلاین</h1>
        <p>
          اجیسنت یک سیستم سازمانی یکپارچه است که به صورت آنلاین به سازمان ها سرویس دهی می نماید. کاربران می توانند با
          توجه
          به نیازهای جاری خود از بین زیر سیستم ها آیتم مورد نظر خود را با میزان امکانات مناسب کسب و کارشان انتخاب
          نمایند.
        </p>
      </div>

      <div class="btn-tab">
        <i :class="{'to-left':tabLeft}" class="bg-tab"></i>

        <span class="h1-se">امروز شروع کنید!</span>
        <span @mouseover="tabHover(true)" @mouseleave="tabHover(false)" class="h2-se">دریافت مشاوره</span>

      </div>

      <div class="image-bottom">

        <img class="circle" src="../assets/img/half-circle.png" alt="image">
        <img class="image-bottom-1" src="../assets/img/image-bottom.png" alt="image">
      </div>

    </div>

    <div id="section2" class="tabs">
      <button class="active"><i class="fa-light fa-arrow-turn-down"></i> مدیریت مخاطبین</button>
      <button><i class="fa-light fa-lock fa-fw"></i> اتوماسیون اداری</button>
      <button><i class="fa-light fa-lock fa-fw"></i> مدیریت نیروی انسانی</button>
      <button><i class="fa-light fa-lock fa-fw"></i> اقدام و گردش کار</button>
      <button><i class="fa-light fa-lock fa-fw"></i> مدیریت مشتریان</button>
      <button><i class="fa-light fa-lock fa-fw"></i> باشگاه مشتریان</button>

    </div>

    <div id="section3" class="tab-box-content">

      <div class="container d-flex flex-wrap">
        <div class="right-section">
          <div class="box-sec">
            <div class="icon">
              <i class="fa-light fa-arrows-minimize"></i>
            </div>
            <div class="txt">
              <h4>یکپارچه سازی درخواست ها</h4>
              <span>تمامی درخواست هایی که به سمت سازمان شما می آیند توسط این زیر سیستم مدیریت خواهند شد . مهم نیست به وسیله تماس تلغنی، پیامک، ایمیل و یا تیکت درخواست مطرح شده باشد.</span>
            </div>
          </div>
          <div class="box-sec">
            <div class="icon">
              <i class="icon-left-arrow"></i>
            </div>
            <div class="txt">
              <h4>توزیع اتوماتیک به بخش مربوطه</h4>
              <span>این درخواست ها به صورت خودکار به سمت واحد یا فرد مسؤل ارسال خواند شد و مخاطب سازمان به صورت لحظه ای در جریان وضعیت درخواست قرار خواهد گرفت.</span>
            </div>
          </div>

        </div>
        <div class="left-section">
          <v-slide-group
              class="pa-4"
              center-active
              show-arrows
          >
            <v-slide-group-item v-for="item in licenses" :key="item.id">
              <div class="box-slider">
                <div class="top-slide">
                  <h2>{{ item.title }}</h2>
                  <h4>{{item.price_1_month.toLocaleString()}} تومان</h4>
                  <span>هر کاربر ماهانه</span>

                </div>
                <div class="bottom-slide">
                  <button>رایگان امتحان کنید!</button>
                  <span>{{item.description	}}</span>
                </div>
              </div>

            </v-slide-group-item>

          </v-slide-group>


        </div>
      </div>


    </div>

    <div id="section4" class="faq-section">

      <h2>سوالات متداول</h2>

      <div class="accordion-custom" v-for="item in faqList" :key="item">

        <h4 @click="show(item)" :class="{'active' :item.show}">{{ item.title }} <i
            class="fa-light fa-arrow-turn-left-down"></i></h4>
        <p :class="{'show' :item.show}">{{ item.body }}</p>
      </div>

    </div>

    <div id="section5" class="contact-section">

      <div class="container d-flex flex-wrap">
        <div class="right-contact">

          <h2>تماس با ما</h2>

          <div class="form-contact">
            <div class="input">
              <label>نام و نام خانوادگی</label>
              <input type="text" placeholder="نام کامل خود را وارد کنید.">
            </div>
            <div class="input-select">
              <label>شماره تماس</label>
              <div class="input-box">
                <input type="number" placeholder="021 88174399">
                <select>
                  <option value="0098">+98</option>
                </select>
              </div>
            </div>
            <div class="input">
              <label>متن پیام</label>
              <textarea placeholder="پیام خود را برای ما بنویسید..."></textarea>
            </div>

            <div class="btn-div w-100">
              <button class="btn btn-send">ارسال</button>
            </div>
          </div>
        </div>

        <div class="left-contact">
          <div class="box-contact">
            <i class="fa-light fa-phone"></i>

            <h6>02188174399</h6>
            <span>شنبه تا چهارشنبه ۹ تا ۱۷</span>
          </div>
          <div class="box-contact">
            <i class="fa-light fa-envelope"></i>

            <h6>Info@agistant.com</h6>
            <span>تیم ما آماده پاسخگویی است.</span>
          </div>
          <div class="box-contact location">
            <i class="fa-light fa-location-dot"></i>

            <h6>تهران، خیابان انقلاب، خیابان فلسطین شمالی، ساختمان سرای نوآوری و صنایع خلاق</h6>
            <span>دفتر مرکزی گروه نرم افزاری امرتاد</span>
          </div>


        </div>
      </div>


    </div>

    <div id="section6" class="about-section">
      <div class="container">
        <div class="d-flex flex-wrap">
          <div class="right-about">

            <div class="logo">
              <img src="../assets/logo-desc.png" alt="">
            </div>

            <div class="text-about">
              <h4>خدمات</h4>
              <ul>
                <li>تولید نرم افزار و شخصی سازی</li>
                <li>استقرار و پشتیبانی</li>
                <li>ارائه خدمات میزبانی و سرورهای ابری</li>
              </ul>

              <h4 class="mt-4">ارزش ها</h4>
              <ul>
                <li>مسئولیت پذیری</li>
                <li>انعطاف پذیری</li>
                <li>کیفیت</li>
                <li>توانمند سازی</li>
              </ul>
            </div>
          </div>
          <div class="left-about">
            <h2>درباره ما</h2>
            <p>
              گروه نرم افزاری اجیستنت با هدف حضور در بازار محصولات نرم افزاری کشور در سال ۱۳۸۶ بنیان گذاری شد. در طی این
              سالها اهداف و ارزش های مجموعه بازنگری و به روز رسانی شده اند و امروز با تمرکز بر چند حوزه تخصصی مشخص قصد
              داریم تا محصولات و خدمات با کیفیتی را در اختیار مشتریان گرامی قرار دهیم. ما براساس فرهنگ سازمانی شکل گرفته
              در این سال ها باور داریم که پیشرفت و توسعه مشتریان و سازمان هایشان در ابعاد مختلف، رشد و توسعه امرتاد را
              در
              پی خواهد داشت. بی وقفه در پی آن هستیم که راه حل هایی کارآمد و به صرفه را جهت ارائه به مشتریان فراهم آوریم
              و
              همچنین آنها را از فرصت های پیش رویشان آگاه سازیم. با این رویکرد همواره آماده ارائه مشاوره به سازمان ها جهت
              بکارگیری راه حل های متناسب با ساختار کسب و کارشان هستیم.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer></Footer>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import axios from "axios";

export default {
  name: "HomeView",
  components: {
    Header, Footer
  },
  data() {
    return {
      faqList: [
        {
          show: true,
          title: 'این یک سوال متداول نمونه برای تست است؟',
          body: 'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد کتابهای زیادی در شصت و سه درصد گذشته حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد'
        },
        {
          show: false,
          title: 'به عنوان سومین سوال متداول نمونه برای تست قرار میدهیم؟',
          body: 'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد کتابهای زیادی در شصت و سه درصد گذشته حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد'
        },
        {
          show: false,
          title: 'پرسش نمونه برای تست است؟',
          body: 'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد کتابهای زیادی در شصت و سه درصد گذشته حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد'
        },
        {
          show: false,
          title: 'این یک سوال متداول نمونه برای تست است؟',
          body: 'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد کتابهای زیادی در شصت و سه درصد گذشته حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد'
        },
      ],
      tabLeft: false,
      licenses: [],

    }
  },
  methods: {
    getLicenses() {
      axios.get('http://46.102.130.145:8001/api/license-plans')
          .then(res => {
           this.licenses = res.data.license_plans
          })
          .catch(err => {
            console.log(err)
          })

    },
    tabHover(hover) {
      if (hover) {
        this.tabLeft = true
      } else {
        this.tabLeft = false
      }

    },
    show(item) {
      item.show = !item.show
    }
  },
  mounted() {
    this.getLicenses()
  }
}
</script>

<style scoped lang="scss">
.main {
  padding: 10px 10px 0;
  background: #F3F4F6;
  border-radius: 40px;

  .text-main {
    text-align: center;
    padding: 10px 0;
    margin-top: 3rem;

    span {
      display: inline-block;
      padding: 10px;
      border-radius: 99px;
      background: #fff;
      color: #333;
      font-size: 14px;
      margin-bottom: 30px;
    }

    h1 {
      font-size: 64px;
      color: #1F2937;
      font-weight: bold;
    }

    p {
      font-size: 18px;
      color: #000000;
      max-width: 750px;
      text-align: center;
      margin: 20px auto 0;
      line-height: 27.9px;
      width: 100%;
    }
  }

  .btn-tab {
    margin: 10px auto;
    background: #fff;
    border-radius: 99px;
    width: max-content;
    padding: 5px;
    position: relative;

    .bg-tab {
      position: absolute;
      width: 50%;
      background: #B4EF11;
      border-radius: 99px;
      top: 4px;
      bottom: 4px;
      right: 4px;
      transition: all ease .4s;

      &.to-left {
        right: calc(50% - 4px)

      }

    }

    span {
      display: inline-block;
      border-radius: 99px;
      padding: 20px 24px;
      cursor: pointer;
      z-index: 1;
      position: relative;
      transition: all ease .4s;
    }

  }

  .image-bottom {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 4rem;
    justify-content: center;
    position: relative;

    img.circle {
      width: 650px;
      max-width: 100%;
      margin: 0 auto;
      filter: drop-shadow(0 -13px 80px rgba(131, 89, 255, .7));


    }

    .image-bottom-1 {
      position: absolute;
      bottom: -200px;
      max-width: 100%;

    }
  }
}

.tabs {
  margin-top: 220px;
  margin-bottom: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  button {
    margin: 10px;
    background: rgba(243, 244, 246, 1);
    border-radius: 99px;
    color: rgba(101, 108, 118, 1);
    padding: 11px 20px;
    display: flex;
    align-items: center;
    font-size: 12px;
    border: 2px solid rgba(243, 244, 246, 1);

    &.active {
      background: #fff;
      border-color: rgba(51, 0, 204, 1);
      font-weight: bold;
      color: rgba(31, 41, 55, 1);

      i {
        color: rgba(51, 0, 204, 1);
      }
    }

    i {
      margin-left: 10px;
    }
  }
}

.tab-box-content {
  background: rgba(243, 244, 246, 1);
  border-radius: 40px;
  padding: 2rem 4rem 2rem 0;
  display: flex;
  flex-wrap: wrap;

  .right-section {
    width: 50%;

    .box-sec {
      display: flex;
      flex-wrap: wrap;
      margin: 40px 0;

      .icon {
        width: 56px;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(51, 0, 204, 1);
        color: #fff;
        border-radius: 50%;
        font-size: 18px;

        .icon-left-arrow {
          width: 24px;
          height: 24px;
          display: inline-block;
          background: center/contain no-repeat url('../assets/img/left-arrow.svg');
        }
      }

      .txt {
        width: calc(100% - 56px);
        padding-right: 15px;

        h4 {
          font-size: 32px;
          font-weight: bold;
          margin-bottom: 20px;
        }

        span {
          line-height: 32px;
          font-size: 16px;
        }
      }
    }
  }

  .left-section {
    width: 50%;
    padding-right: 2rem;
    display: flex;
    align-items: center;
    direction: ltr;

    .box-slider {
      direction: rtl;
      background: rgb(244, 246, 255);
      background: linear-gradient(180deg, rgba(244, 246, 255, 1) 0%, rgba(214, 222, 254, 1) 100%);
      padding: 20px;
      max-width: calc(50% - 20px);
      margin-left: 20px;
      text-align: center;
      border-radius: 40px;
      border: 1px solid #fff;

      .top-slide {
        h2 {
          font-size: 40px;
          font-weight: bold;
        }

        h4 {
          color: #3300CC;
          margin-bottom: 2px;
          font-size: 32px;
        }

        span {
          font-size: 20px;
          color: #8965F6;
        }
      }

      .bottom-slide {
        margin-top: 10rem;
        padding-bottom: 15px;

        button {
          width: 100%;
          background: #B4EF11;
          color: #1F2937;
          border-radius: 99px;
          padding: 15px;
          font-size: 20px;
          margin-bottom: 20px;

        }

        span {
          color: #1F2937;
          font-size: 12px;
        }
      }
    }

  }
}

.faq-section {
  padding: 6rem 2rem;


  h2 {
    text-align: center;
    font-weight: bold;
    font-size: 64px;
    margin-bottom: 2rem;
  }

  .accordion-custom {
    border-bottom: 1px solid #DFE3EA;
    max-width: 900px;
    margin: 0 auto;

    h4 {
      text-align: center;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      cursor: pointer;
      padding: 25px 0;
      margin: 0;
      transition: all ease .4s;

      i {
        width: 37px;
        height: 37px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 15px;
        background: #F3F4F6;
        border-radius: 50%;
        color: #656C76;
        font-size: 13px;
        transition: all ease .4s;
      }

      &.active {
        color: #3300CC;
        font-weight: bold;

        i {
          color: #3300CC;
          background: #E5DDFF;
          transform: rotateX(180deg);
        }
      }
    }

    p {
      line-height: 32px;
      font-size: 16px;
      color: #656C76;
      transition: all ease .4s;
      margin-bottom: 0;
      height: 0;
      opacity: 0;
      overflow: hidden;

      &.show {
        padding: 10px 0 15px;
        height: auto;
        opacity: 1;
        overflow: visible;
      }
    }
  }
}

.contact-section {
  padding: 4rem 6rem;
  background: #EEF4FF;
  border-radius: 40px;
  display: flex;
  flex-wrap: wrap;

  input:focus, textarea:focus {
    outline: none;

  }

  .right-contact {
    width: 60%;
    padding-left: 3rem;

    h2 {
      font-size: 64px;
      font-weight: bold;
      margin-bottom: 4rem;
    }

    .input {
      width: 100%;
      margin: 10px 0;

      label {
        width: 100%;
        padding: 10px;
        font-size: 16px;
        color: #344054;
      }

      input, textarea {
        width: 100%;
        background: #fff;
        height: 55px;
        border: 1px solid #D0DDF0;
        padding: 10px 20px;
        border-radius: 99px;
      }

      textarea {
        height: 135px;
        border-radius: 25px;
      }
    }

    .input-select {
      width: 100%;

      label {
        width: 100%;
        padding: 10px;
        font-size: 16px;
        color: #344054;

      }

      .input-box {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        background: #fff;
        width: 100%;
        height: 55px;
        border: 1px solid #D0DDF0;
        border-radius: 99px;

        input {
          height: 100%;
          width: calc(100% - 70px);
          text-align: left;
          padding: 10px;
          -moz-appearance: textfield;

        }

        select {
          -moz-appearance: checkbox;
          direction: ltr;
          color: #666;
          text-align: center;
          width: 60px;
          margin-right: auto;
          padding-left: 15px;
          cursor: pointer;
        }

      }
    }

    .btn-div {
      padding-top: 30px;

      button {
        background: #B4EF11;
        border-radius: 99px;
        height: 60px;
        color: #1F2937;
        width: 100%;
        transition: all ease .4s;

        &:hover {
          opacity: .7;
        }

      }
    }
  }

  .left-contact {
    width: 40%;
    padding-right: 15px;

    .box-contact {
      background: #FFFFFF;
      border-radius: 40px;
      padding: 2rem;
      margin-bottom: 15px;
      transition: all ease .4s;

      &:hover {
        background: #DFE5FC;
      }

      &.location {
        h6 {
          font-size: 20px;
        }
      }

      i {
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #1F2937;
        color: #fff;
        border-radius: 50%;
      }

      h6 {
        padding: 15px 0;
        margin-bottom: 0;
        color: #4337D8;
        direction: ltr;
        text-align: right;
        font-weight: 600;
        width: 100%;
        font-size: 24px;
      }

      span {
        color: #475467;
        font-size: 16px;
        font-weight: 100;
      }

    }


  }
}

.about-section {
  padding: 3rem 0;

  .right-about {
    padding: 2rem;
    background: #F3F4F6;
    width: 35%;
    border-radius: 40px;

    .logo {
      width: 100%;
      text-align: center;
      border: 1px solid #D6DEFE;
      border-radius: 24px;
      padding: 24px 30px;
      margin-bottom: 2rem;

      img {
        max-width: 100%;
      }
    }

    ul {
      padding-left: 0;
      margin-bottom: 0;
      padding-right: 25px;

      li {
        padding: 5px 0 5px 10px;
      }
    }

    .text-about {
      h4 {
        font-size: 32px;
        font-weight: 500;
      }
    }
  }

  .left-about {
    width: 65%;
    padding: 4rem;

    h2 {
      font-weight: bold;
      font-size: 64px;
      margin-bottom: 4rem;
    }

    p {
      font-size: 16px;
      line-height: 36px;
    }
  }
}

@media (max-width: 576px) {

  .main {
    .text-main {
      p {
        font-size: 12px;
        line-height: 18.6px;
      }

      h1 {
        font-size: 32px;
      }
    }

    .image-bottom {
      margin-top: 7rem;

      img.circle {
        width: 280px;
      }

      .image-bottom-1 {
        bottom: 35px;
      }
    }

  }
  .tabs {
    margin-top: 20px;
    overflow: auto;
    justify-content: right;

    button {
      white-space: nowrap;
      font-size: 16px;
    }
  }
  .tab-box-content {
    padding: 10px;

    .right-section {
      width: 100%;

      .box-sec {
        .txt {
          width: 100%;
          padding-top: 10px;

          h4 {
            font-size: 20px;
          }

          span {
            font-size: 14px;
            line-height: 24px;
          }
        }
      }

    }

    .left-section {
      width: 100%;
      padding: 0;

      .box-slider {
        width: 100%;
        max-width: none;
        margin: 0;
      }
    }
  }
  .faq-section {
    padding: 10px 15px;

    h2 {
      font-size: 40px;
    }

    .accordion-custom {
      h4 {
        font-size: 16px;
        text-align: right;

        i {
          min-width: 37px;
          margin-right: auto;
        }
      }

      p {
        font-size: 14px;
      }
    }
  }
  .contact-section {
    padding: 10px;

    .left-contact {
      width: 100%;
      padding: 0;
    }

    .right-contact {
      width: 100%;
      padding: 0;

      h2 {
        font-size: 40px;
      }
    }
  }
  .about-section {
    padding: 10px;
    margin-top: 2rem;

    .left-about {
      width: 100%;
      padding: 0;
      order: 1;

      h2 {
        font-size: 40px;
        margin-bottom: 10px;
      }

      p {
        font-size: 16px;
        line-height: 36px;
      }
    }

    .right-about {
      order: 2;
      width: 100%;


    }
  }

}

</style>
