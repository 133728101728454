<template>
  <div class="p-4">
    <div class="login-box">

      <div class="logo">
        <img src="../../assets/logo-desc.png" alt="">
      </div>

      <div class="form-box text-center">
        <div class="title">
          <button @click="toBack" class="back">
            <i class="fa-regular fa-angle-right"></i>
          </button>
          <h2>اعتبارسنجی</h2>
        </div>


        <div class="input">
          <label for="">کد تایید ارسال شده به شماره خود را وارد کنید:</label>
          <div class="input-group-cus">
            <input
                v-for="(value, index) in inputs"
                :key="index"
                :id="'inp-'+index"
                v-model="inputs[index]"
                @input="handleInput($event, index)"
                @keydown.backspace="handleBackspace($event, index)"
                maxlength="1"
                type="text"
                class="input-box"
            />

          </div>
        </div>

        <div class="btns">

          <v-btn @click="setOtp" :loading="loading" class="brn btn-primary">ادامه</v-btn>
          <span>قبلا اکانت ساخته اید؟  <a href="">ورود</a></span>
        </div>


      </div>

      <img class="bg" src="../../assets/img/half-circle.png" alt="">

    </div>
    <v-snackbar
        :timeout="3000"
        v-model="snackbar"
        :color="colorSnack"
    >
      {{ textSnack }}

      <template v-slot:actions>
        <v-btn

            variant="tonal"
            @click="snackbar = false"
        >
          <i class="fa-regular fa-close"></i>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import store from "@/store";
import Cookies from 'js-cookie';

export default {
  name: "OtpView",
  data() {
    return {
      apiUrl: store.getters.baseUrl,
      loading: false,
      snackbar: false,
      otp: '',
      textSnack: false,
      colorSnack: '',
      inputs: ["", "", "", "", ""],
      headers: {
        'Accept': '*/*',
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      },
    }
  },
  methods: {
    toBack(){
      this.$router.push('/auth/register')
    },
    setOtp() {

      let mobile = store.getters.getMobile
      if (this.inputs.join('').length < 5) {
        this.showMsg('لطفا کد را به درستی وارد کنید', 'red')
        return
      }
      this.loading = true
      let body = {
        phone_number: mobile,
        otp: this.inputs.join(''),
      }
      axios.post(this.apiUrl + 'api/otp/verify', body,
          {headers: this.headers})
          .then(res => {
            this.loading = false
            if (res.data.status === 200) {
              if(res?.data?.api_token != undefined){
                Cookies.set('token', res.data.api_token, {expires: 7, path: 'https://agistant.com/'});
                localStorage.setItem('urlPanel',res.data.url)
                console.log(res.data.url)
                this.$router.push({ name: 'Completed'})
                return;

              }

              let token = res.data.access_token
              this.setCookie(token)
              store.commit('setLogin', token)
              this.$router.push('/auth/organizational-information')

            }
            if (res.data.status === 400) {
              this.showMsg('کد وارد شده صحیح نیست!', 'red')
              return
            }
            if (res.data.status === 409) {
              this.showMsg('قبلا ثبت نام شده اید', 'red')
              return
            }

          })
          .catch(err => {
            console.log(err)
            this.loading = false
            if(err.response.status === 400){
              this.showMsg('کد وارد شده صحیح نیست', 'red')
              this.inputs = ["", "", "", "", ""]
              document.getElementById("inp-0").focus();
              return
            }
            this.showMsg('مشکلی پیش آمده', 'red')

          })
    },
    setCookie(token) {
      Cookies.set('token', token, {expires: 7, path: '/'});
    },
    getCookie() {
      return Cookies.get('token');
    },
    deleteCookie() {
      Cookies.remove('token');
    },
    onlyNumber() {
      this.mobile = this.mobile.replace(/[^0-9]/g, '');

    },
    showMsg(msg, color) {
      this.snackbar = true
      this.textSnack = msg
      this.colorSnack = color
    },
    handleInput(event, index) {
      const value = event.target.value;
      if (!/^\d$/.test(value)) {
        this.inputs[index] = "";
        return;
      }
  if(index > 3){
    this.setOtp()

  }

      if (index < this.inputs.length - 1) {
        this.$nextTick(() => {
          const nextInput = event.target.nextElementSibling;
          if (nextInput) nextInput.focus();
        });
      }

    },
    handleBackspace(event, index) {

      if (!this.inputs[index] && index > 0) {
        this.$nextTick(() => {
          const prevInput = event.target.previousElementSibling;
          if (prevInput) prevInput.focus();
        });
      }

    },
  },
  mounted() {
    let mobile = store.getters.getMobile
    if(mobile === ''){
      this.$router.push('/auth/register')
    }

  }
}
</script>

<style scoped>

</style>
