<template>
  <div class="p-4">
    <div class="register-box">

      <div class="logo">
        <img src="../../assets/logo-desc.png" alt="">
      </div>

      <div class="form-box text-center">
        <div class="title">
          <h2 class="w-100">بازیابی گذرواژه</h2>
          <span>برای بازیابی شماره موبایل خود را وارد نمایید.</span>
        </div>

        <div class="input mt-4">
          <label>شماره موبایل</label>
          <input class="text-left direction-ltr" type="text" placeholder="0912 1234567" />
        </div>
        <div class="btns pt-3">

          <button class="brn btn-primary">بازیابی</button>
          <span>قبلا اکانت ساخته اید؟  <router-link to="/auth/login">ورود</router-link></span>

        </div>

      </div>

      <img class="bg" src="../../assets/img/half-circle.png" alt="">

    </div>
  </div>
</template>

<script>
export default {
  name: "ForgetView"
}
</script>

<style scoped>

</style>
