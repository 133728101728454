<template>
<div class="p-4">
  <div class="login-box">
    
    <div class="logo">
      <img src="../../assets/logo-desc.png" alt="">
    </div>

    <div class="form-box text-center">
      <div class="title">
        <h2 class="w-100">ورود</h2>
        <span>به اجیستنت خوش آمدید.</span>
      </div>

      <div class="input">
        <label for="">شماره موبایل</label>
        <input class="text-left direction-ltr" type="text" placeholder="0912 1234567" />
      </div>
      <div class="input">
        <label for="">گذرواژه</label>
        <input :type="showPass ? 'text':'password'" placeholder="" />
        <i @click="showPass = !showPass" class="fa-light " :class=" showPass ? 'fa-eye-slash' : 'fa-eye'"></i>
        <router-link to="/auth/forget-pass">فراموشی رمز عبور</router-link>
      </div>

      <div class="btns">

        <button class="brn btn-primary">ورود</button>
        <span>هنوز ثبت نام نکرده اید؟  <router-link to="/auth/register">ثبت نام</router-link></span>
      </div>

    </div>

    <img class="bg" src="../../assets/img/half-circle.png" alt="">

  </div>
</div>
</template>

<script>
export default {
  name: "LoginView",
  data(){
    return{
      showPass:false
    }
  }
}
</script>

<style scoped lang="scss">

.login-box{
  border-radius: 40px;
  height: 100%;
  min-height: calc(100vh - 3rem);
  background: #F3F4F6;
  width: 100%;
  overflow: auto;
  position: relative;
  padding: 15px;
  .bg{
    position: absolute;
    bottom: 0;
    width: 600px;
    left: calc(50% - 300px);
    filter: blur(70px);
    opacity: .2;
    max-width: 100%;

  }
  @media (max-width: 576px) {
   .bg{
     width: 100%;
     left: 0;
   }
    overflow-x: hidden;
  }
  
  .logo{
    text-align: center;
    width: 100%;
    padding:2rem 15px;
    margin-bottom:3rem;
    img{
      max-width: 100%;
      width: 115px;
    }
  }

  .form-box{
    max-width: 445px;
    width: 100%;
    margin: 0 auto;
    padding-bottom: 2rem;
    z-index: 1;
    position: relative;
    .title{
      h2{
        font-size: 24px;
        font-weight: 600;
      }
      span{
        font-size: 14px;
      }
    }
    .input{
      width: 100%;
      margin-bottom: 10px;
      position: relative;
      a{
        width: 100%;
        font-size: 12px;
        display: block;
        text-align: left;
        padding: 10px;
        color: #3300CC;
      }
      i{
        position: absolute;
        left: 24px;
        bottom: 55px;
        cursor: pointer;
        transition: all ease .4s;
        &:hover{
          opacity: .7;
        }
      }
      label{
        margin: 10px ;
        text-align: right;
        width: 100%;
      }
      input{
        width: 100%;
        background: #fff;
        border-radius: 99px;
        border: 1px solid #D0DDF0;
        height: 50px;
        padding:10px 15px;
      }
    }
  }
  .btns{
    button{
      width: 100%;
      height: 50px;
      background: #B4EF11;
      color: #000;
      border-radius: 99px;
      text-align: center;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 20px;
    }
  }

}



</style>
