<template>
  <div class="p-4">
    <div class="login-box">

      <div class="logo">
        <img src="../../assets/logo-desc.png" alt="">
      </div>

      <div class="form-box text-center">
        <div class="title">
          <button @click="toBack" class="back">
            <i class="fa-regular fa-angle-right"></i>
          </button>
          <h2>اطلاعات سازمان</h2>
        </div>


        <form @submit.prevent="submit">
          <div class="input" :class="{'error':errorName}">
            <label>نام سازمان</label>
            <input v-model="company_name" class="text-left" type="text" placeholder=""/>
          </div>
          <div class="input select" :class="{'error':errorNumber}">
            <button type="button" @click="showDrop = !showDrop" :value="company_personnel">{{ textDrop }} <i class="fas fa-angle-down"></i></button>
            <div class="drop-down-box" :class="{'d-none':!showDrop}">
              <button type="button" @click="choice(0,'1 تا 10')" value="0">1 تا 10</button>
              <button type="button" @click="choice(1,'10 تا 20')" value="1">10 تا 20</button>
              <button type="button" @click="choice(2,'20 تا 30')" value="2">20 تا 30</button>
              <button type="button" @click="choice(3,'30 تا 40')" value="3">30 تا 40</button>
              <button type="button" @click="choice(4,'بیش از 40')" value="4">بیش از 40</button>
            </div>

          </div>
          <!--        <div class="input">
                    <label>دامین</label>
                    <input v-model="domain" class="text-left" type="text" placeholder="" />
                  </div>
          <div :class="{'error':errorPortal}" class="input sub-domain">
            <label>نام پرتال (اختیاری)</label>
            <input v-model="portal" class="text-left" type="text" placeholder=""/>
            <span>.agistant.com</span>
          </div>
          <span v-if="errorPortal" class="text-danger w-100 d-block text-left">این نام قبلا انتخاب شده است</span>


          <div :class="{'error':errorSub , 'error':error}" class="input sub-domain">
            <label>ساب دامین </label>
            <input v-model="sub" class="text-left" type="text" placeholder=""/>
            <span>.agistant.com</span>
          </div>
          <span v-if="errorSub" class="text-danger w-100 d-block text-left">این نام قبلا انتخاب شده است</span>

-->
          <div class="btns pt-4">

            <v-btn type="submit" :loading="loading" class="brn btn-primary">ادامه</v-btn>
            <span>قبلا اکانت ساخته اید؟  <a href="">ورود</a></span>
          </div>
        </form>

      </div>

      <img class="bg" src="../../assets/img/half-circle.png" alt="">

    </div>
    <v-snackbar
        :timeout="3000"
        v-model="snackbar"
        :color="colorSnack"
    >
      {{ textSnack }}

      <template v-slot:actions>
        <v-btn

            variant="tonal"
            @click="snackbar = false"
        >
          <i class="fa-regular fa-close"></i>
        </v-btn>
      </template>
    </v-snackbar>
  </div>

</template>

<script>
import store from "@/store";
import Cookies from "js-cookie";

export default {
  name: "InformationVue",
  data() {
    return {
      apiUrl: store.getters.baseUrl,
      loading: false,
      snackbar: false,
      textSnack: false,
      colorSnack: '',
      domain: '',
      sub: '',
      portal: '',
      password: '',
      company_name: '',
      company_personnel: '',
      errorName:false,
      errorNumber:false,
      errorSub: false,
      textDrop:'تعداد پرسنل',
      errorPortal: false,
      showDrop:false,
      headers: {
        'Accept': '*/*',
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
    }
  },
  methods: {
    checkToken(){
      console.log(this.getCookie())

    },
    choice(val , txt){
      this.textDrop = txt
      this.company_personnel = val
      this.showDrop = false

    },
    submit(){
      this.errorName = false
      this.errorNumber = false
      if(this.company_name === ''){
        this.errorName =true
        this.showMsg('لطفا  نام سازمان را وارد کنید','red')
        return
      }
      if(this.company_personnel === ''){
        this.showMsg('لطفا   تعداد پرسنل را وارد کنید','red')
        this.errorNumber = true
        return;
      }
      let data = {
        company_personnel: this.company_personnel,
        company_name: this.company_name,
      }
      store.commit('setInformation',data)
      this.$router.push('/auth/choice-password')

    },
  /*  checkSub() {

      if(this.sub === ''){
        this.showMsg('لطفا ساب دامین را وارد کنید','red')
        this.error =true
        return
      }
      this.loading = true
      let token = this.getCookie()
      let auth = 'Bearer ' + token
      axios.get(this.apiUrl + 'api/check_domain?subdomain=' + this.sub, {headers: {'Authorization': auth}})
          .then(res => {
            this.loading = false
            if (res.data.exists) {
              this.showMsg('این ساب دامین قبلا انتخاب شده است!', 'red')
              this.errorSub = true
              return
            }
            if (res.data.exists === false) {
              this.checkPortal()
            }

          })
          .catch(error => {
            this.loading = false
            if (error.response.status === 401) {
              console.log(error.response.data.message)

            }


          })

    },
    checkPortal() {
      this.loading = true
      let token = this.getCookie()
      let auth = 'Bearer ' + token
      axios.get(this.apiUrl + 'api/check_domain?subdomain=' + this.portal, {headers: {'Authorization': auth}})
          .then(res => {
            this.loading = false
            if (res.data.exists) {
              this.showMsg('این نام قبلا انتخاب شده است!', 'red')
              this.errorPortal = true
            }
            if (res.data.exists === false) {
              let data = {
                domain: this.domain,
                sub_domain: this.sub,
                portal: this.portal,
                company_personnel: this.company_personnel,
                company_name: this.company_name,
              }
              store.commit('setInformation',data)
              setTimeout(()=>{
                console.log(store.getters.getInformation)
              },2000)
            }

          })
          .catch(error => {
            this.loading = false
            if (error.response.status === 401) {
              console.log(error.response.data.message)

            }


          })

    },*/
    toBack(){
      this.$router.push('/auth/register')
    },
    setCookie(token) {
      Cookies.set('token', token, {expires: 7, path: '/'});
    },
    getCookie() {
      return Cookies.get('token');
    },
    deleteCookie() {
      Cookies.remove('token');
    },
    onlyNumber() {
      this.company_personnel = this.company_personnel.replace(/[^0-9]/g, '');

    },
    showMsg(msg, color) {
      this.snackbar = true
      this.textSnack = msg
      this.colorSnack = color
    },

  },
  mounted() {
    this.checkToken()
  }
}
</script>

<style scoped lang="scss">
.input.select{
  position: relative;
  >button{
    width: 100%;
    background: #fff;
    border-radius: 99px;
    border: 1px solid #D0DDF0;
    height: 50px;
    padding: 10px 15px;
    text-align: right;
    display: flex;
    align-items: center;
    color: #474F5D;
    i{
      position: static;
      margin-right: auto;
      color: #474F5D;

    }
  }
  .drop-down-box{
    width: 100%;
    position: absolute;
    top: 100%;
    right: 0;
    padding: 15px;
    border: 1px solid #D7DBE0;
    z-index: 1;
    border-radius: 24px;
    background: #fff;
    text-align: right;
    justify-content: right;
    button{
      padding:7px 10px;
      text-align: right;
      font-size: 14px;
      color: #474F5D;
      transition: all ease .4s;
      width: 100%;
      &:hover{
        background: #eee;
        border-radius: 15px;
      }
    }

  }
}




</style>
