<template>
  <div class="p-4">
    <div class="completed-box w-100 text-center">
      <button @click="getDashboard()" class="btn btn-primary mx-auto">ورود به داشبورد</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "completedComponent",
  data(){
    return{
      apiToken:'',
      url:'',
    }
  },
 methods:{
   getDashboard(){


     let url = localStorage.getItem('urlPanel')
     if(!url.includes('http')){
       url = 'https://'+url
     }

     window.location.href = url

   }
 }
}
</script>

<style scoped>

</style>
