<template>
<footer>
  
  <div class="logo w-100">
    <img src="../assets/logo-w.png" alt="">
  </div>
  <span class="text-center w-100">
    تمامی حقوق این وبسایت متعلق به اجیستنت میباشد.{{ new Date().getFullYear() }}©
  </span>

</footer>
</template>

<script>
export default {
  name: "footer-Component"
}
</script>

<style scoped lang="scss">

footer{
  background: #1F2937;
  padding:25px 15px;
  text-align: center;
  span{
    color: #DFE5FC;
    padding: 10px 0 0;
    display: block;
    font-size: 16px;
    font-weight: 100;
  }
  
}

@media (max-width: 576px) {
  footer{
    .logo{
      img{
        width: 100%;
      }

    }
    span{
      font-size: 12px;
    }
  }

}

</style>
